
import { Table, TableData, TableHeader, TableRow } from '@/components/table'
import { EventModel } from 'tradingmate-modules/src/models/api/events'
import { Services } from 'tradingmate-modules/src/services'
import Utils from 'tradingmate-modules/src/Utils'
import { Vue, Component } from 'vue-property-decorator'
import Paging from '@/components/actions/Paging.vue'
import EventFilter from '../../../../../tradingmate-modules/src/filters/EventFilter'
import { PagedResultSet } from '../../../../../tradingmate-modules/src/models/api/common'
import Badge from '@/components/status/Badge.vue'

@Component({
  components: {
    Table,
    TableRow,
    TableData,
    TableHeader,
    Paging,
    Badge
  }
})

export default class AdminContentEvents extends Vue {
  private events: PagedResultSet<EventModel> | null = null

  private loading = false

  private isoToDisplayDate = Utils.isoToDisplayDate

  mounted (): void {
    this.getEvents()
  }

  private filter = new EventFilter({ Page: 1, Active: undefined })

  getEvents (): void {
    this.loading = true
    Services.API.Events.GetAllEvents(this.filter)
      .then((res) => {
        this.events = res
      }).finally(() => {
        this.loading = false
      })
  }

  onEdit (id: string): void {
    this.$router.push({ name: 'Edit Event', params: { id: id } })
  }

  private isMarkingSeen = ''
  onMarkedSeen (event: EventModel): void {
    this.isMarkingSeen = event.EventId
    Services.API.Admin.Content.MarkSeenEvent(event.EventId)
      .then((result) => {
        // if (!this.events) return
        // const e = this.events.Items.find(x => x.EventId === event.EventId)
        event.MarkedSeen = true
      })
      .finally(() => {
        this.isMarkingSeen = ''
      })
  }

  onPaged (page: number): void {
    this.filter.Page = page
    this.getEvents()
  }
}

